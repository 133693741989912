// import { NavLink } from 'react-router-dom';
// import links from './links';
'use client'
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar
} from '@mui/material'
import Image from 'next/image'

const logo = require('src/assets/webp/logo.webp')

function DrawerComponent({
  mobileOpen,
  handleDrawerToggle
}: {
  mobileOpen: boolean
  handleDrawerToggle: any
}) {
  const drawerWidth = 240
  const drawer = (
    <div>
      <Toolbar>
        <Image
          alt="logo"
          src={logo}
          style={{ width: '100%', height: '100%' }}
        />
      </Toolbar>
      <Divider sx={{ bgcolor: 'rgba(0,0,0,0)' }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}`}
            target="_blank">
            <ListItemText primary="Sign In" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            target="_blank"
            href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}/schedule`}>
            <ListItemText primary="Try for Free" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        zIndex: 'fab',
        display: { xs: 'block', sm: 'none' }
      }}
      aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            border: 'none'
          }
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  )
}

export default DrawerComponent
