'use client'

import Toolbar from '@mui/material/Toolbar'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
const logo = require('src/assets/webp/logo.webp')

export const ToolbarComponent = () => {
  const theme: any = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)
  return (
    <Toolbar>
      <Link href="/" shallow={true}>
        <Image
          src={logo}
          alt="Loop Logo"
          style={{ height: 60, width: 'auto', marginRight: 12 }}
        />
      </Link>
      <div style={{ flexGrow: 1 }} />
      <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
        <Button
          LinkComponent={'a'}
          href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}`}
          variant="text"
          sx={{ color: '#122937' }}>
          Sign In
        </Button>
        <Button
          // variant="outlined" style={{ color: '#356C80', border: "2px solid #DF5F2E", borderRadius: 5 }}
          LinkComponent={'a'}
          target="_blank"
          href={`${process.env.NEXT_PUBLIC_LOOP_APP_URL}/schedule`}>
          TRY FOR FREE
        </Button>
      </Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { md: 'none' }, color: theme.colors.blue }}>
        {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
      </IconButton>
    </Toolbar>
  )
}

export default ToolbarComponent
