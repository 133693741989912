// export  DrawerComponent from './Drawer/Drawer.component.tsx';

import DrawerComponent from './Drawer/Drawer.component';
import Footer from './Footer/Footer.component';
import ToolbarComponent from './Toolbar/Toolbar.component';

export {
  DrawerComponent as Drawer,
  Footer,
  ToolbarComponent as Toolbar,
};

