'use client'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { Next13ProgressBar } from 'next13-progressbar'
import { Suspense } from 'react'
import { Footer } from 'src/components'
import AnnouncementBanner from 'src/components/AnnouncementBanner'
import Appbar from 'src/components/Appbar/Appbar.component'

const DialogComp = dynamic(() => import('src/components/Dialog'), {
  ssr: false
})

interface iLayout {
  children: React.ReactNode
}

const Body = ({ children }: iLayout) => {
  return (
    <body>
      <div id="root" style={{ overflowX: 'hidden' }}>
        <Suspense fallback={null}>
          <Next13ProgressBar
            color="#196E82"
            height="4px"
            options={{ showSpinner: false }}
          />
          <AnnouncementBanner />
        </Suspense>
        <DialogComp />

        <Appbar />

        <main>{children}</main>

        <Footer />
      </div>
      <Script
        src="https://guidebar-backend-727ab3a68ba9.herokuapp.com/install/tryloop_ai"
        defer={true}
      />
    </body>
  )
}
export default Body
