import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/blog.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/Body.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/PostHogPageView.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/app/tailwind.css");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/context/DialogContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/src/styles/_app.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/loop-landing/loop-landing/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Sora\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\",\"latin-ext\"],\"display\":\"swap\"}],\"variableName\":\"sora\"}")